import apiRequest from "../api/apiRequest";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiConfig } from "../config/apiConfig";
import { closeFunctionality } from "../util/createModal";
import { isTouchDevice } from "../util/device-detection";
import { startLoader, stopLoader } from "../util/loader";
import { getBrandDetailsFromBrandJson, storeBookingApiPayload } from "../util/share";
import { isTestFcn } from "./booking-payload";
import { validEstimate } from "./confirmation";
import { throwBookingError } from "./molly-flow";
let moment = require('moment');

const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
export function configureNotesComments():boolean{
    let flag = false;
    return flag;
}

function priceNoteExists(){
    const priceNote = sessionStorage.getItem("PriceNote");
    if(priceNote && priceNote!== "" && priceNote!== "null" && priceNote!= undefined){
        return true;
    }
    return false;
}

function appendPriceNotes(bookingPayload:any){
    
    const otcEstimateTrue = !bookingPayload.JobFrequency.toLowerCase().includes("recurring") && validEstimate() && !priceNoteExists();
    if(bookingPayload.IsLeadOnly && otcEstimateTrue){
        const estimateData = JSON.parse(sessionStorage.getItem("mollyEstimate") as string);
        const oneTimeClean = bookingPayload.JobFrequencyDetail.toLowerCase().includes("Not a Move In or Move Out Clean");
        let estimateText;
        if(oneTimeClean){
            estimateText = `$${Math.round(estimateData?.estimateOccasionalRangeLow)} - $${Math.round(estimateData?.estimateOccasionalRangeHigh)}`;
        }
        else{
            estimateText = `$${Math.round(estimateData?.estimateMoveRangeLow)} - $${Math.round(estimateData?.estimateMoveRangeHigh)}`;
        }
        sessionStorage.setItem("PriceNote",`Price: ${estimateText}`);

    }
}

export function payloadHelper(bookingPayload:any){
    bookingPayload.Note = '';
    const outStr = sessionStorage.getItem("PriceNote");
    const outStr2 = sessionStorage.getItem("noZipNote");
    let noteText = '';
    if(outStr && !bookingPayload.JobFrequency.toLowerCase().includes("recurring")){
        noteText = noteText.concat(outStr + ', ');
    }
    if(outStr2){
        noteText = noteText.concat(outStr2 + ', ');
    }
    noteText = noteText.concat(`Customer Type: ${bookingPayload.CustomerType}, MLY Frequency: ${bookingPayload.JobFrequency}`);
    if(bookingPayload.JobFrequency.toLowerCase().includes("one-time")){
        noteText = noteText.concat(`, ${bookingPayload.JobFrequencyDetail}`);
    }
    if(bookingPayload.EstimateSquareFeet && bookingPayload.EstimateBathrooms){
        if(bookingPayload.CustomerType.toLowerCase().includes("residential")){
            noteText = noteText.concat(`, Square Feet: {${bookingPayload.EstimateSquareFeet}}, Bedrooms: {${bookingPayload.EstimateBedrooms}} , Bathrooms: {${bookingPayload.EstimateBathrooms}}`); 
        }
        else{
            noteText = noteText.concat(`, Square Feet: {${bookingPayload.EstimateSquareFeet}}, Offices: {${bookingPayload.EstimateBedrooms}} , Bathrooms: {${bookingPayload.EstimateBathrooms}}`);
        }
    }
    const preference = sessionStorage.getItem('PreferredCommunicationText') as string;
    if(preference){
        if(preference?.toLowerCase().includes("text")){
            noteText = noteText.concat(`, Text OptIn: Yes`);
        }
    }
    if(!bookingPayload.Note.includes(noteText)){
        bookingPayload.Note = bookingPayload.Note.concat(noteText);
    }
    if(configureNotesComments()){
        bookingPayload.Comments = bookingPayload.Comments.concat(noteText);
    }
    
    return bookingPayload;
}

function generateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';
    const array = new Uint32Array(length);
    window.crypto.getRandomValues(array);

    for (const value of array) {
        result += characters.charAt(value % charactersLength);
    }
    return result;
}

function getSessionAndCorrelationID(){
    let obj = {
        correlationID: '',
        sessionID: ''
    }

    const sessionID = localStorage.getItem("sessionIDFr");
    if(sessionID){
        obj.sessionID = sessionID;
    }
    else{
        obj.sessionID = generateRandomString(5);
        localStorage.setItem("sessionIDFr", obj.sessionID);
    }
    obj.correlationID = `${generateRandomString(5)}-${Date.now()}`;
    return obj;
}

export async function callBookingApi(bookingPayload: any, browerCloseCheck?: boolean){
    startLoader();
    appendPriceNotes(bookingPayload);
    bookingPayload = payloadHelper(bookingPayload);
    
    let sessionID: string | undefined;
    let correlationID: string | undefined;
    const sessionAndCorelation = getSessionAndCorrelationID();
    sessionID = sessionAndCorelation.sessionID;
    correlationID = sessionAndCorelation.correlationID;
    if(correlationID)
        bookingPayload.correlationId = correlationID;

    let url = '';
    if(correlationID && sessionID){
        url = `${apiConfig.BOOKING_API_URL}&correlationId=${correlationID}&sessionId=${sessionID}`
    }
    else{
        url = apiConfig.BOOKING_API_URL;
    }
    
    const request = {
        method: 'POST',
        url: url,
        keepalive: true,
        data: bookingPayload
    };
    const callFromCalendar = sessionStorage.getItem("callFromCalendar");
    if(callFromCalendar==="true"){
        // happy flow
        const isBookingDone = sessionStorage.getItem("isBookingDone");
        if(isBookingDone && isBookingDone==="true"){
            return;
        }
    }
    apiRequest(request)
        .then((response: any) => {
            if(response){
                if(callFromCalendar==="true"){
                    sessionStorage.setItem("isBookingDone","true");
                }
                storeBookingApiPayload(bookingPayload, response);
                window.history.replaceState({}, "" , window.location.href);
                if(browerCloseCheck) {
                    sessionStorage.setItem('IsLeadOnly','false');
                    sessionStorage.setItem("bookingLeadSent", "true");
                    stopLoader();
                    if(isTouchDevice()){
                        closeFunctionality();
                    }
                    
                } 
                if(!browerCloseCheck) {
                    document.querySelectorAll("form").forEach((f)=>{
                        f.reset();
                    });
                    window.location.pathname = '/confirmation';
                }
            }
            else{
                const flag = isTouchDevice() && browerCloseCheck ? closeFunctionality() : throwBookingError();
            }
            
        })
        .catch((error:any) => {
            const flag = isTouchDevice() && browerCloseCheck ? closeFunctionality() : throwBookingError();
        })
}

export async function getFranchiseDetails(addressParam:string) : Promise<any> {
    const url:any = apiConfig.LocateLocationApiWithRoundRobinFalse.replace('sAddressParamValue', encodeURIComponent(addressParam));
    const result:any = getCachedAPIResponse(url);
    return result;
}

export function determineFlowType(WebLocationId:number) : any {
    // Function to determine whether OS flow or Lead flow
    const url = `${apiConfig.GET_ATTRIBUTE_DATA}?apikey=${process.env.JS_API_KEY}&franchiseWeblocationId=${WebLocationId}`;
    return getCachedAPIResponse(url);

}

export async function checkTechAvailability(arg:string) : Promise<any> {
    const weblocationId = sessionStorage.getItem('franchiseWebLocationId');
    const zipValue = sessionStorage.getItem('zipcode');
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    const maxWeeks = brandData?.calendarMaxWeeksCap ? brandData?.calendarMaxWeeksCap: 8;
    let estFlag;
    if(arg.toLowerCase().includes('recurring')){
        estFlag = true;
    }else{
        estFlag = false;
    }
    const dynamic_url = `${apiConfig.AppointMentAPi}?PostalCode=${zipValue}&WebLocationId=${weblocationId}&NumberOfDays=${7*maxWeeks}&DateStart=${moment().format('MM/DD/YYYY')}&IsEstimate=${estFlag}&apikey=${process.env.JS_API_KEY}`;
    return getCachedAPIResponse(dynamic_url);
    
}

export async function configureMollyEstimate() : Promise<boolean>{
// This method will call an API to confirm whether the estimate needs to be shown in the calendar screen or not.
    let locationID = sessionStorage.getItem('franchiseWebLocationId') ? sessionStorage.getItem('franchiseWebLocationId') : localStorage.getItem('weblocationId');
    const val = await determineFlowType(Number(locationID));
    const returnVal = val?.options?.mollyOneTimeCleansDisableEstimatePricing;
    return !returnVal;
}

export async function confirmMlyLeadSourceAPI() : Promise<string>{
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    let endpoint:string = apiConfig.GenericLeadSourceEndpoint;
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    if(brandData){
        if(brandData?.send_brand_leadsource_api){
            endpoint = apiConfig.MollyLeadSourceEndpoint;
        }
        else{
            endpoint = apiConfig.GenericLeadSourceEndpoint;
        }
    }
    return endpoint;
    
}

export async function setDefaultLeadSource(): Promise<any> {
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    const obj = {
        "LeadSourceID": brandData?.default_leadSourceID,
        "LeadSource": brandData?.default_leadSource
    }
    return obj;
}

export async function calcEstimate(bookingPayload:any){
    startLoader();
    sessionStorage.removeItem("noCalendarPricing");
    let payload ={
        "franchiseWebLocationId": bookingPayload.WebLocationId,
        "LeadSourceId": bookingPayload.LeadSourceId,
        "SecondaryLeadSourceId": 0,
        "Note": "",
        "EstimateTitle": "",
        "FirstName": bookingPayload.FirstName,
        "LastName": bookingPayload.LastName,
        "Address": bookingPayload.Address + bookingPayload.Address2,
        "City": bookingPayload.City,
        "State": bookingPayload.State,
        "PostalCode": bookingPayload.PostalCode.trim(),
        "Email": bookingPayload.Email,
        "Phone": bookingPayload.Phone,
        "PreferredCommunicationType": bookingPayload.PreferredCommunicationType,
        "EstimateSquareFeet": Number(bookingPayload.EstimateSquareFeet),
        "EstimateBedrooms": Number(bookingPayload.EstimateBedrooms),
        "EstimateBathrooms": Number(bookingPayload.EstimateBathrooms),
        "ReceiveEmailUpdates": true,
        "conceptId": conceptId 
    };
    const request = {
        method: 'POST',
        url: apiConfig.calculateEstimate,
        data: payload
    };

   return apiRequest(request)
        .then((response: any) => {
            stopLoader();
            if(response){
                sessionStorage.setItem("mollyEstimate",JSON.stringify(response));
            }
            else{
                sessionStorage.setItem("noCalendarPricing","true");
            }
            
        })
        .catch((error:any) => {
            stopLoader();
            sessionStorage.setItem("noCalendarPricing","true");
        })
    
}

export function calcEstFail(){
    const varFlag = sessionStorage.getItem("noCalendarPricing");
    if(varFlag && varFlag=="true"){
        return true;
    }
    return false;
}

export async function contactUsEndpoint(bookingPayload:any){
    startLoader();
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    let noZipText= brandData.osflow_contactUs_comments;
    const requestBody: any = {
        "FirstName": bookingPayload.FirstName,
        "LastName": bookingPayload.LastName,
        "ZipCode": bookingPayload.ZipCode,
        "PostalCode": bookingPayload.PostalCode,
        "Phone": bookingPayload.Phone,
        "Email": bookingPayload.Email,
        "City": bookingPayload.City, 
        "State": bookingPayload.State,
        "Country": bookingPayload.Country,
        "Address": bookingPayload.Address,
        "Address2": bookingPayload.Address2,
        "Comments": "",
        "SignUpForUpdates": true,
        "IsLocalized": false,
        "IsNewCustomer": "", 
        "IsTest": isTestFcn(),
        "conceptId": conceptId,
        "conceptCode": conceptCode,
        "VendorId": brandData.vendorId,
        "VendorName": brandData.vendorName,
        "IsLeadOnly": true,
        "Note": ""
    };
    requestBody.Note = requestBody.Note.concat(noZipText);
    const request = {
        method: 'POST',
        url: apiConfig.CONTACT_US_SEND,
        data: requestBody
    };
    return apiRequest(request)
        .then((response)=>{
            stopLoader();
            if(response){
                storeBookingApiPayload(requestBody,response);
                window.history.replaceState({}, "" , window.location.href);
                document.querySelectorAll("form").forEach((f)=>{
                    f.reset();
                });
                window.location.pathname = '/confirmation';
            }
            else{
                throwBookingError();
            }
        })
        .catch((err)=>{
            stopLoader();
            throwBookingError();
        });
}