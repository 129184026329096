import { locationSearchMapConfig } from "./config";
import { moveMapTo } from "./moveMapTo";
import { ResultsList } from "./ResultsList";
import { locationSearchMapConfig as config } from './config';
import { LatLng } from "./models/LatLng";
import { startLoader } from "../util/loader";

export class Search
{
    map: H.Map;
    resultsList: ResultsList;
    searchResMapIcon: H.map.Icon;
    marker!: H.map.Marker;
    pinFirstResult: boolean = false;
    constructor(map: H.Map, resultsList: ResultsList)
    {
        this.map = map;
        this.resultsList = resultsList;
        this.searchResMapIcon = new H.map.Icon(searchResMarker);
        this.map.addEventListener('mapviewchangeend', (event: any) => {
            const viewModel = this.map?.getViewModel();
            const lookAtData = viewModel?.getLookAtData();
            const bounds = lookAtData?.bounds;

            if (bounds) {
                this.resultsList.filterResults(bounds.getBoundingBox());
            }
            const objects = this.map.getObjects();
            for(let i=0; i<objects.length; ++i) {
                // if(objects[i].type === H.map.Object.Type.MARKER) {
                if (objects[i] instanceof H.map.Marker) {
                    const markerGeometry = this.marker.getGeometry() as H.geo.Point;
                    this.resultsList.sortCurrentListByDistanceToPin(markerGeometry);
                }
            }
            if(this.pinFirstResult){
                this.resultsList.pinFirstResult();}
            else {
                this.resultsList.unpinFirstResult();
            }
            // this.resultsList.updateListView();
        });
        const locationSearchInput = document.getElementById('location-search') as HTMLInputElement;
        const findMrElectButton = document.querySelector('.location-search-form .form-cta .primary-btn') as HTMLButtonElement;
        const useMyLocationBtn = document.querySelector('.location-search-wrapper .use-my-location-btn') as HTMLButtonElement;
        locationSearchInput?.addEventListener('keydown', (e: KeyboardEvent) =>
        {
            if (e.code === 'Enter')  
            {
                const searchText = (e.target as HTMLInputElement).value;
                this.search(searchText);
                e.preventDefault()
            } 
        });
        findMrElectButton?.addEventListener('click', (e: MouseEvent) =>
        {
            const searchText = (document.getElementById('location-search') as HTMLInputElement).value;
            this.search(searchText);
        });
        useMyLocationBtn?.addEventListener('click', (e: MouseEvent) =>
        {
            this.knowMyLocationSearch();
        });
        const mobileWrapper = document.querySelector('.map-wrapper-mobile') as HTMLElement;
        if(mobileWrapper){
            mobileWrapper.style.display='none';
            function showHideDiv(val: number) {
                if (val === 2 && map) {
                    mobileWrapper.style.display = 'none';
                }
                if (val === 1 && map) {
                    mobileWrapper.style.display = 'block';
                }
            }
    
            // Add event listeners
            const button1 = document.getElementById('map-view') as HTMLButtonElement | null;
            const button2 = document.getElementById('list-view') as HTMLButtonElement | null;
    
            if (button1 && button2) {
                button1.addEventListener('click', () => showHideDiv(1));
                button2.addEventListener('click', () => showHideDiv(2));
            }

        }
        let mapModal = document.getElementById("map-modal") as HTMLElement;
        if(mapModal) {
            const mapModalClose = document.getElementById("close-map-modal");
            mapModalClose?.addEventListener('click', () => {
                mapModal.style.display = "none";
            });
            let mapModalMobile = document.getElementById("map-modal-mobile") as HTMLElement;
            const mapModalCloseMobile = document.getElementById("close-map-modal-mobile");
            mapModalCloseMobile?.addEventListener('click', () => {
                mapModalMobile.style.display = "none";
            });
        }
        if(locationSearchInput){
            locationSearchInput.value = sessionStorage.getItem('temp_zipcode') ?? '';
            sessionStorage.removeItem("temp_zipcode");
            sessionStorage.removeItem('nearbyLocations');

        }
    }

    async search(searchQuery: string,isRemove: boolean = true)
    {
        if(isRemove){
            sessionStorage.removeItem('nearbyLocations');
        }
        searchQuery = searchQuery.trim();
        this.setPinFirstResult(searchQuery);
        const resDBASearch = this.resultsList.searchByDBAName(searchQuery);
        localStorage.setItem("resDBASearchLength",resDBASearch.length);
        //console.log('search: searchByDBAName: ', resDBASearch);
        if (this.isDBASearch(searchQuery))
        {
            this.loadSearchByDBAResult(resDBASearch);
            return;
        }
        const searchBox = document.getElementById('location-search') as HTMLInputElement;
        if(searchBox.value != ''){
        const res = await this.heremapSearch(searchQuery);
        //console.log('search: res: ', res);

        if (res.items && res.items.length > 0)
        {
            let bbox: H.geo.Rect;
            const topRes = res.items[0];
            
            let type = res.items[0][`${res.items[0].resultType}Type`];
            if (res.items[0].resultType === 'street' || res.items[0].resultType === 'place')
            {
                type = res.items[0].resultType;
            }

            this.focusMap(res.items[0].position, type);
            this.addMarker(res.items[0].position);
            this.resultsList.unpinFirstResult();
        }
        else{ // search by dba 
            this.loadSearchByDBAResult(resDBASearch);
        }
      }
      if (searchQuery.length === 0)
        {
            console.log('reset search and map');
            this.resultsList.unpinFirstResult();
            this.resetMap();
            this.resultsList.resetList();
        }
    }
    async heremapSearch(searchQuery: string)
    {
        let apiUrl = `${locationSearchMapConfig.geoCodeSearchApiUrl}?apiKey=${locationSearchMapConfig.apiKey}&q=${searchQuery}&in=countryCode:USA,CAN`;
     
        const res = await fetch(apiUrl)
            .then(data => data.json());
        return res;
    }
    resetMap()
    {
        moveMapTo(this.map,
            { lat: Number(config.initView.lat), lng: Number(config.initView.lng) },
            Number(config.initView.zoom));
    }
    focusMap(position: LatLng, areaType: string)
    {
        let zoom = this.getZoom(areaType);
        moveMapTo(this.map, position, zoom);
    }
    knowMyLocationSearch()
    {
        navigator.geolocation.getCurrentPosition((position) =>
        {
            //console.log('knowMyLocation: position: ', position);
            const coords = `${position.coords.latitude},${position.coords.longitude}`;
            const revGeocodeUrl = `${config.revGeoCodeSearchApiUrl}?at=${coords}&apiKey=${config.apiKey}`
            fetch(revGeocodeUrl)
                .then(data => data.json())
                .then(res =>
                {
                    if (res.items.length > 0)
                    {
                        const zipcode = res.items[0].address.postalCode;
                        const searchBox = document.getElementById('location-search') as HTMLInputElement;
                        searchBox.value = zipcode;
                        this.search(zipcode);
                    }
                });
        });
    }
    
    loadSearchByDBAResult(resDBASearch: any)
    {
        if (resDBASearch.length > 0)
        {
            //console.log('loadSearchByDBAResult: focusing by dba name: ');
            const topResDBASearch = resDBASearch[0].item;
            const bbox = this.getBoundingBox(topResDBASearch.latitude, topResDBASearch.longitude, 0.125);
            this.resultsList.filterResults(bbox);
            const position = { lat: topResDBASearch.latitude, lng: topResDBASearch.longitude };
            this.focusMap(position, 'place');
            this.addMarker(position);
        }else {
            const locationFoundText = document.querySelector('.location-total-text') as HTMLElement;  ;
            const noLosAvailableMsg = document.getElementById('no-locs-available-msg') as HTMLElement;  
            locationFoundText.innerHTML = noLosAvailableMsg?.innerHTML;
            locationFoundText.style.lineHeight = "normal";
            locationFoundText.style.display = "block";
            let locationList = document.querySelector(".location-list") as HTMLUListElement;
            if(locationList){
                locationList.style.display = "none";
            }
            return;
        }
    }
    
    getBoundingBox = (lat: number, lng: number, range: number): H.geo.Rect =>
    {
        const north = lat + range;
        const south = lat - range;
        const east = lng + range;
        const west = lng - range;
        const bbox = new H.geo.Rect(north, west, south, east);
        return bbox;
    }
    getZoom(areaType: string){
        let zoom = 4;
        if (areaType === 'street')
        {
            zoom = 16;
        }
        else if (areaType === 'place')
        {
            zoom = 14;
        }
        else if (areaType === 'postalCode')
        {
            zoom = 9; // 20km zoom
        }
        else if (areaType === 'city')
        {
            zoom = 9; // 20km zoom
        }
        else if (areaType === 'state')
        {
            zoom = 6;
        }
        else if (areaType === 'country')
        {
            zoom = 5;
        }
        return zoom;
    }
    addMarker(position: LatLng){        
        if(this.marker) {
            this.map.removeObject(this.marker);
        }
        this.marker = new H.map.Marker(position, 
            {icon: this.searchResMapIcon});
        this.map.addObject(this.marker);
    }
    setPinFirstResult(query: string){
        this.pinFirstResult = this.isDBASearch(query) || this.isZipSearch(query);
    }
    isDBASearch(query: string){
        const val = (document.getElementById("brandName") as HTMLInputElement)?.value?.replace("-","");
        return query.replace(/\W/g, "").toLowerCase().includes(val);
    }
    isZipSearch(query: string){
        return /\d{5}/.test(query.trim());
    }
}
const searchResMarker = '<svg xmlns="http://www.w3.org/2000/svg" id="a" viewBox="0 0 59 59" width="44" height="42"><defs><style>.b{fill:#fff;}</style></defs><path d="M47.86,23.09c0-11.23-9.11-20.34-20.34-20.34S7.18,11.86,7.18,23.09c0,6.89,3.44,12.98,8.69,16.66l11.66,11.66,11.66-11.66c5.25-3.68,8.69-9.76,8.69-16.66Z"/><circle class="b" cx="27.52" cy="23.09" r="12.27"/></svg>';