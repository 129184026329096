import { getDynamicMenuResponse } from '../util/getDynamicMenu';
export class ServiceList {
   // Replace 'any[]' with the actual data structure from your API
    // public dynamicMenuURLResponse: any = {};
    public showSkuAsCategory = false;
    maxCharacterCount:number = 375;

    constructor() {

      const tabContent = document.getElementById('accordion-service');
      const tabsUl  = document.querySelector('.residential-accordion .req-call-tabs');
      this.showReadMoreButton();

      if(tabContent || tabsUl)
        this.getMenu()
    }

    async getMenu() {

        const tabContent = document.getElementById('accordion-service');
        const tabsUl  = document.querySelector('.residential-accordion .req-call-tabs')
        
        let response = await getDynamicMenuResponse();
        const data = response?.serviceMenu;
        const dbaName: string | null = localStorage.getItem('dbaName');
        let options;
        if(dbaName){
          options = data["country"][0]?.brand[0]?.webLocation[0].option;
        }
        else{
          options = data["country"][0]?.brand[0]?.option;
        }
        
        if(data["country"][0]?.brand[0]?.ShowSkuAsCategory){
          this.showSkuAsCategory = true;
        }
        const brandMenu = options;
        const list =  brandMenu?.map((item:any)=> this.generateServiceList(item))
        const tabs =  brandMenu?.map((item:any, index:number)=> this.generateTabs(item, index)) 

        if(tabContent){
          list?.forEach((elm:any)=>{
            tabContent.innerHTML+= elm;
          })
        }

        if(tabsUl && brandMenu?.length > 0){
          this.toggleTabs(brandMenu[0].name.toLowerCase())
          tabsUl.innerHTML = tabs?.join('') || '';
        }

        const residentialDom = document.querySelector('.residential-accordion') as HTMLDivElement;
        const tabsBtn = residentialDom.querySelector('.req-call-tabs')

        tabsBtn?.addEventListener('click',(event)=>{
          const domCheck = event.target as HTMLElement;
          if(domCheck.classList.contains("tabs-service"))
            this.toggleTabs(domCheck.id?.replace(/\s+/g, "-"))
        })

    }

    private toggleTabs(targetID:string){
      const targetClass = `service-acc-tab--${targetID.replace("accor-","")}`;
      const residentialDom = document.querySelector('.residential-accordion') as HTMLDivElement;
      const allItems = residentialDom?.querySelectorAll(".accordion-icon-list .card-list") as any;
      allItems?.forEach((elem:any)=>{
        elem.style.display = "none";
      });
      const targetElements = residentialDom?.querySelectorAll(`.accordion-icon-list .${targetClass}`);
      if(targetElements){
        targetElements.forEach((elem:any)=>{
          elem.style.display = "flex";
        });
      }
    }

      
    private generateTabs (data:any, index: any){
      const imageName = data.name.split(' ')[0].toLowerCase().replace(/\s/g, '');
      return(
          `<li class="req-call-tablist">
          <input type="radio" class="req-call-radio hidden tabs-service"   id="accor-${data.name.toLowerCase()}" data-nbly="accor-${data.name.toLowerCase()}" name="request-call" value="${index}" ${index==0 ? 'checked=' : ''} >
          <label for="accor-${data.name.toLowerCase()}" class="req-call-btn res-req-btn">
              <img src="/brand/_assets/images/icons/${imageName}-icon-white.svg" class="primary-icon" alt="${data.name}" width="16" height="16">
              <img src="/brand/_assets/images/icons/${imageName}-icon-gray.svg" class="gray-icon" alt="${data.name}" width="16" height="16"> ${data.name}</label>
      </li>`
      )
    }

    showReadMoreButton(){
      const pTag = document.querySelector('.read-more-wrap .residential-text') as HTMLElement;
      if(pTag){
        const numberOfCharacters = pTag.innerText.length;
        if(numberOfCharacters < this.maxCharacterCount){
          const showMoreButton = document.getElementById("readMoreButton")as HTMLElement;
          if(showMoreButton){
            showMoreButton.classList.add("hidden")
          }
        }
      }
    }
  
    private generateAccordionItem(service: any, data:any, index:number): string {
      let name = data?.name.trim().replace(/\s+/g,"-").toLowerCase();
      let arrowButton = "";
      if(service.menu.length > 0){
        arrowButton = `<div class="accordion-close accordion-arrow">
              <img src="/brand/_assets/images/icons/plus.svg" alt="Plus" width="16" height="16">
            </div>
            <div class="accordion-open accordion-arrow">
              <img src="/brand/_assets/images/icons/minus.svg" alt="Minus" width="16" height="16">
            </div>`;
      };
      
      return `
        <li class="card-list service-acc-tab--${name}">
          <button type="button" class="list-accordion-btn" data-accordion-target="#service-${name}-${index}" aria-expanded="true" aria-controls="service-${name}-${index}">
            <div class="icon-block">
              <div class="icon-wrap">
                <svg class="icon">
                  <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#star-primary"></use>
                </svg>
              </div>
              <div class="icon-block-text">
                <h3 class="card-title">${this.showSkuAsCategory && data?.category?.length == 1 ? data?.name : service.name}</h3>
              </div>
            </div>
            ${arrowButton}
          </button>
          <div class="card-info hidden" id="service-${name}-${index}">
            <ul class="accordion-list">
              ${service.menu.map(visibleService).join(' ')}
            </ul>
          </div>
        </li>
      `;
    }
  
    public generateServiceList(data:any): string {
      return `
          ${data?.category.map((service:any, index:any) => this.generateAccordionItem(service, data, index)).join('')}
      `;
    }
  }

 
  const serviceList = new ServiceList();

  function visibleService(subservice:any){
    if(subservice.visibility){
      return `<li><a href="${window.location.origin}${subservice.link}">${subservice.name}</a></li>`;
    }
  }
  