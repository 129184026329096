import apiRequest from "../api/apiRequest";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { isTestEnvironment } from "../apiCaching/POST-Requests/helperModules/helperFunctions";
import { errorMessage } from "../common/errorMessages";
import { apiConfig } from "../config/apiConfig";
import { apiCaching } from "../config/cachingConfig";
import { REGEX } from "../constants/regex";
import { bookingPayload } from "../mly/booking-payload";
import { allAddressList, discoverSuggestions, discoveryAPI, getAddressDetails, handleMissingData } from "../util/discovery";
import { handleMobileInput } from "../util/errorHandlers";
import { StateHandler } from "../util/getStateFun";
import { gtmCustomEventLeadFlow } from "../util/gtm-custom-event-lead-flow";
import { startLoader, stopLoader } from "../util/loader";
import { debounce,getBrandDetailsFromBrandJson, getCampaignName, removeAllEventListeners, storeBookingApiPayload } from "../util/share";
export class ReferralService {
    bookingPayload: any;
    sendButton: any;
    referFirstName: any;
    referLasttName: any;
    referPhone: any;
    referZipCode: any;
    referEmail: any;
    referralFirstName: any;
    referralLastName: any;
    referralPhone: any;
    referralZipCode: any;
    referralEmail: any;
    referralAddress: any;
    referralAddress2: any;
    sentCommentAndNote :boolean = false;

    constructor() {
        this.init();
        this.bookingPayload = bookingPayload;

        document.querySelector('.cta-wrapper .primary-btn')?.addEventListener("click", () => {
            const enteredAddr = this.referralAddress?.value.concat(" ", this.referralAddress2.value, " ", this.bookingPayload.PostalCode);
            const suggestAddr = this.referralAddress?.getAttribute("data-suggestion") as any;
            let output: any;
            const suggestAddrInp = document.getElementById('suggested-address-radio') as HTMLInputElement;
            sessionStorage.setItem("suggestion", "true");
            if (suggestAddrInp?.checked) {
                output = suggestAddr?.textContent;
                this.referralAddress.value = output;
                const suggestJSON = suggestAddr !=='undefned' && JSON.parse(suggestAddr);
                const suggestedAddress = getAddressDetails(suggestJSON?.address);
                sessionStorage.setItem('suggestedAddress', JSON.stringify(suggestedAddress));
            }
            else {
                output = enteredAddr?.textContent;
            }
            sessionStorage.setItem('sAddressParam', output);
            this.onReferralButtonClick();
        });
    }

    public init() {
        this.initInputs();
        this.initButtonEvent();
        this.setupInlineValidation();
    }

    private handleNextBtnClick() {
        const inputAddressBumper = document.querySelector('.step-2 #modal-address-1') as HTMLInputElement;
        const inputAddressBumper2 = document.querySelector('.step-2 #modal-address-2') as HTMLInputElement;
        const inputCity = document.querySelector('.step-2 #modal-city') as HTMLInputElement;
        const inputState1 = document.querySelector('.step-2 #modal-states') as HTMLInputElement;
        const inputZipCode = document.querySelector('.step-2 #modal-zipcode') as HTMLInputElement;
        const sAddressBumper = inputAddressBumper?.value.trim() || '';
        const sAddressBumper1 = inputAddressBumper2?.value.trim() || '';
        const sCityBumper = inputCity?.value.trim() || '';
        const sStateBumper = inputState1?.value.trim() || '';
        const sZipCodeBumper = inputZipCode?.value.trim() || '';
        const sAddressParam = `${sAddressBumper} ${sAddressBumper1} ${sCityBumper} ${sStateBumper} ${sZipCodeBumper}`;
        if (this.validateForm()) {
            this.franchiseLookupAddressApi(sZipCodeBumper,true,sAddressParam);
        }
    }

    public initInputs() {
        
        // Referee inputs variables declaration
        this.referFirstName = document.getElementById('my-refer-fname');
        this.referLasttName = document.getElementById('my-refer-lname');
        this.referPhone = document.getElementById('my-refer-phone');
        this.referZipCode = document.getElementById('my-refer-zip-code');
        this.referEmail = document.getElementById('my-refer-email');

        // Referal inputs variables declaration
        this.referralFirstName = document.getElementById('frnd-refer-fname');
        this.referralLastName = document.getElementById('frnd-refer-lname');
        this.referralPhone = document.getElementById('frnd-refer-phone');
        this.referralZipCode = document.getElementById('frnd-refer-zip-code');
        this.referralEmail = document.getElementById('frnd-refer-email');
        this.referralAddress = document.getElementById('frnd-refer-address');
        this.referralAddress2 = document.getElementById('frnd-refer-address2');
        this.addInputEventListeners();
    }

    public addInputEventListeners() {

        if (this.referFirstName) {
            this.referFirstName.addEventListener('input', () => this.handleCommonInputError(this.referFirstName, `${this.referFirstName.id}-error-msg`, REGEX.sendName, errorMessage.fname));
        }
        if (this.referLasttName) {
            this.referLasttName.addEventListener('input', () => this.handleCommonInputError(this.referLasttName, `${this.referLasttName.id}-error-msg`, REGEX.sendName, errorMessage.lname));
        }
        if (this.referPhone) {
            this.referPhone.addEventListener('input', handleMobileInput.bind(this));
        }

        if (this.referZipCode) {
            this.referZipCode.addEventListener('input', () => this.handleCommonInputError(this.referZipCode, `${this.referZipCode.id}-error-msg`, REGEX.sendZip, errorMessage.zip));
        }
        if (this.referEmail) {
            this.referEmail.addEventListener('input', () => this.handleCommonInputError(this.referEmail, `${this.referEmail.id}-error-msg`, REGEX.sendEmail, errorMessage.email));
        }
        if (this.referralFirstName) {
            this.referralFirstName.addEventListener('input', () => this.handleCommonInputError(this.referralFirstName, `${this.referralFirstName.id}-error-msg`, REGEX.sendName, errorMessage.fname));
        }
        if (this.referralLastName) {
            this.referralLastName.addEventListener('input', () => this.handleCommonInputError(this.referralLastName, `${this.referralLastName.id}-error-msg`, REGEX.sendName, errorMessage.lname));
        }
        if (this.referralPhone) {
            this.referralPhone.addEventListener('input', handleMobileInput.bind(this));
        }
        if (this.referralZipCode) {
            this.referralZipCode.addEventListener('input', () => this.handleCommonInputError(this.referralZipCode, `${this.referralZipCode.id}-error-msg`, REGEX.sendZip, errorMessage.zip));
        }
        if (this.referralEmail) {
            this.referralEmail.addEventListener('input', () => this.handleCommonInputError(this.referralEmail, `${this.referralEmail.id}-error-msg`, REGEX.sendEmail, errorMessage.email));
        }

        if (this.referralAddress) {
            this.referralAddress.addEventListener('input', () => this.handleCommonInputError(this.referralAddress, `${this.referralAddress.id}-error-msg`, REGEX.sendAddress1, errorMessage.address));
            const debouncedSetAutoSuggested = debounce(this.setAutoSuggested.bind(this), 300);
            this.referralAddress.addEventListener('input',debouncedSetAutoSuggested);
        }

        if (this.referralAddress2) {
            this.referralAddress2.addEventListener('input', () => this.handleCommonInputError(this.referralAddress2, `${this.referralAddress2.id}-error-msg`, REGEX.sendAddress2, errorMessage.address2));
        }
    }

    private setAutoSuggested = async (e: any) => {
        e.preventDefault()
        sessionStorage.setItem("suggestion", "false");
        this.referralAddress.removeAttribute("suggestion");
        sessionStorage.setItem("manualCity", "");
        sessionStorage.setItem("manualState", "");
        discoverSuggestions(this.referralZipCode, this.referralAddress);
    }

    public initButtonEvent() {
        const referralButton = document.querySelector('.refer-wrapper .primary-btn');
        if (referralButton) {
            referralButton.addEventListener('click', () => this.onReferralButtonClick());
        }
    }

    public async onReferralButtonClick() {
        console.log('Referral button clicked');
        if (this.validateFields()) {

            startLoader();
            console.log('All fields are valid');
            try {
                this.readFormData();
                const data = this.getReferralFormData();
                console.log('Referral form data:', data);
                const addrFlag = sessionStorage.getItem("suggestion");
                const brandData = await this.getBrandData();
                const enteredAddr = this.referralAddress?.value.concat(" ", this.referralAddress2.value, " ", this.bookingPayload.PostalCode);
                const suggestAddr = this.referralAddress?.getAttribute("data-suggestion") as string;
                if (addrFlag === "false" && suggestAddr != null && suggestAddr !== 'undefined' && enteredAddr) {
                    this.openPropertyValidationModal(enteredAddr, suggestAddr);
                } else {
                    let zipCode = '';
                    if (brandData?.send_lead_referee_info) {
                        zipCode = data.referralZipCode;
                    } else if (brandData?.send_lead_referrer_info) {
                        zipCode = data.referZipCode;
                    }
                    if (zipCode) {
                        this.franchiseLookupAddressApi(zipCode);
                    }else{
                        // Display the error msg if zipcode is empty
                        stopLoader();
                        this.handleButtonClickAndError('referal-form-error-modal-id', 'refer-form-system-error');
                    }

                }
            } catch (e) {
                console.log('Error while submitting referral form', e);
            }
        }
    }


    public async franchiseLookupAddressApi(zipCode: any, isAddressBumper: boolean = false,sAddressParam ='') {
        if (!zipCode) return;

        const request = {
            url: apiConfig.LocateLocationApiWithRoundRobinTrue.replace('sAddressParamValue', encodeURIComponent(isAddressBumper ? sAddressParam : zipCode))
        };

        try {
            const result = await getCachedAPIResponse(request.url, apiCaching.LocateLocationApiWithRoundRobinTrue);
            if (isAddressBumper) this.closeAddressBumperForm();
            await this.handleApiResponse(result, zipCode);
        } catch (error: any) {
            await this.handleError(error, zipCode);
        }
    }

    public async handleApiResponse(result: any, zipCode: any) {
        if (result?.length == 0) {
            await this.handleNoServiceArea();
        } else if (result?.length > 0) {
            this.bookingApiCall(result[0], zipCode);
        }
    }

    public async handleNoServiceArea() {
        stopLoader();
        const brandData = await this.getBrandData();
        if (!brandData?.send_lead_refer_contactus_api) {
            const btnClick = document.getElementById('referal-form-error-modal-id');
            btnClick?.click();
            const noServiceEle = document.getElementById('refer-form-no-service');
            if (noServiceEle) {
                noServiceEle.classList.remove('hidden');
                const referSystemError = document.getElementById('refer-form-system-error');
                if (referSystemError) {
                    referSystemError.classList.add('hidden');
                }
            }
        } else {
            this.contactUsApiCall();
        }
    }

    async getBrandData(): Promise<any> {
        const conceptCodeElement = document.getElementById('conceptCode') as HTMLInputElement;
        if (!conceptCodeElement) {
            throw new Error('Concept code element not found');
        }
        const conceptCode = conceptCodeElement.value;
        if (!conceptCode) {
            throw new Error('Concept code is empty');
        }
        return await getBrandDetailsFromBrandJson(conceptCode);
    }

    private async handleError(error: any,zipCode: any) {
        stopLoader();
        const brandData = await this.getBrandData();
        let errorMessage = JSON.parse(error?.message);
        if (errorMessage?.message) {
            if (errorMessage.message === 'Full address is needed to narrow down results') {
                const step1 = document.querySelector('.step-1') as HTMLElement;
                if (step1) {
                    step1.classList.add('hidden');
                }
                const stateHandler = new StateHandler();
                stateHandler.getStateFun();
                const findLocalBtn = document.querySelector(".find-local") as HTMLElement;
                findLocalBtn?.click();
                const step2 = document.querySelector('.step-2') as HTMLElement;
                if (step2) {
                    step2.classList.remove('hidden');
                    step2.classList.add('block');
                    const closeLocalModal = document.querySelector('.close-modal-btn');
                    closeLocalModal?.addEventListener('click', function handleClick(event) {
                        step2.classList.add("hidden");
                        step1.classList.remove("hidden");
                    });
                }

                const modalZipCode = document.querySelector('.step-2 #modal-zipcode') as HTMLInputElement;
                modalZipCode.value = zipCode;

                
                this.sendButton = removeAllEventListeners('.step-2 .form-cta button');
                if (this.sendButton) {
                    this.sendButton.addEventListener("click", this.handleNextBtnClick.bind(this));
                }
            }
        } else {
            this.handleGenericError(brandData);
        }
    }

    public handleGenericError(brandData: any) {
        stopLoader();
        if (!brandData.send_lead_refer_contactus_api) {
            const btnClick = document.getElementById('referal-form-error-modal-id');
            btnClick?.click();
            const zipMessage = document.getElementById('modal-zipcode-error');
            if (zipMessage) {
                zipMessage.innerHTML = `We're sorry. Something went wrong. Please try again later.`;
            }
        } else {
            this.contactUsApiCall();
        }
    }

    public async bookingApiCall(result: any, zipCode: any) {
        console.log('Booking API call');
        startLoader();
        const { franchiseWebLocationId, franchiseId } = result;
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        const campaignName = getCampaignName();
        const brandData = await getBrandDetailsFromBrandJson(conceptCode);
        let formData: any = await this.getFormData(brandData, zipCode);
        
        let { city, state, finalAddress } = this.getAddressDetails(formData,brandData);
      
            const resAdd = await  handleMissingData(null,null,zipCode);
            if(resAdd){
                city = resAdd.city;
                state = resAdd.state;
            }
        const requestBody = this.createRequestBody(formData, city, state, finalAddress, zipCode, conceptCode, campaignName, brandData, franchiseWebLocationId, franchiseId);

        sessionStorage.setItem('booking', JSON.stringify(requestBody));
        const request = {
            method: 'POST',
            url: apiConfig.BOOKING_API_URL,
            data: requestBody
        };

        apiRequest(request)
            .then((response: any) => {
                stopLoader();
                storeBookingApiPayload(requestBody, response); //store booking api payload
                // Removed campaign name session storage
                sessionStorage.removeItem('campaignName');

                const submitObj = {
                    event: 'opus_refferal_form_submit_ev',
                    status: 'success'
                };
                gtmCustomEventLeadFlow(submitObj);
                this.redirectConfirmationPage();

            })
            .catch((err) => {
                stopLoader();
                this.handleButtonClickAndError('referal-form-error-modal-id', 'refer-form-system-error');

                const submitObj = {
                    event: 'opus_refferal_form_submit_ev',
                    status: 'failure'
                };
                gtmCustomEventLeadFlow(submitObj);
            });
    }

    public async getFormData(brandData: any, zipCode: any) {
        let formData: any = {};
        if (brandData.send_lead_referee_info) {
            formData = {
                name: this.referralFirstName?.value + ' ' + this.referralLastName?.value,
                email: this.referralEmail?.value ?? '',
                phone: this.referralPhone?.value ?? '',
                address: this.referralAddress?.value ?? '',
                address2: this.referralAddress2?.value ?? '',
            };
        } else if (brandData.send_lead_referrer_info) {
            const resultAdd = await discoveryAPI(zipCode);
            const outputData = resultAdd?.items[0];
            formData = {
                name: this.referFirstName?.value + ' ' + this.referLasttName?.value,
                email: this.referEmail.value ?? '',
                phone: this.referPhone.value ?? '',
                address: (document.getElementById('default_address_refer_a_friend') as HTMLInputElement)?.value ?? "No Address collected",
                address2: ''
            };
            if (outputData?.address?.city) {
                formData.city = outputData?.address?.city;
            }
            if (outputData?.address?.stateCode) {
                formData.state = outputData?.address?.stateCode;
            }
        }
        return formData;
    }

    public getAddressDetails(formData: any,brandData: any) {
        let item = allAddressList?.filter((item: any) => item.title.trim() == formData?.address?.trim())[0];
        let manualCity = sessionStorage.getItem("manualCity");
        let manualState = sessionStorage.getItem("manualState");
        let city = manualCity || item?.address.city || "Not Specified";
        let state = manualState || item?.address.state || "Not Specified";

        let finalAddress: any = '';
        let getsuggestionFlag = sessionStorage.getItem("suggestion");
        const suggestAddr = sessionStorage.getItem('suggestedAddress') as string;
        
        if (getsuggestionFlag === "true" && suggestAddr && brandData?.send_lead_referee_info) {
            city = JSON.parse(suggestAddr).city;
            state = JSON.parse(suggestAddr).stateCode;
            finalAddress = JSON.parse(suggestAddr).addr1;
        } else {
            finalAddress = formData?.address;
            if(brandData?.send_lead_referrer_info){
                city = formData?.city;
                state = formData?.state;
            }
        }

        return { city, state, finalAddress };
    }

    public createRequestBody(formData: any, city: string, state: string, finalAddress: string, zipCode: string, conceptCode: string, campaignName: string, brandData: any, franchiseWebLocationId: any, franchiseId: any) {
        const conceptId = (document.getElementById('conceptId') as HTMLInputElement)?.value;
        const url = window.location.href;
        let vendorId;
        let vendorName;

        if (brandData) {
            vendorId = brandData.vendorId;
            vendorName = brandData.vendorName;
        }

        const emailOptOut = brandData.enable_emailOptOut;
        const defaultLeadSourceId = brandData.default_leadSourceID || 0;

        let noteComment = brandData?.refer_note_text || '';
        const requestBody: any = {
            IsLocalized: true,
            FirstName: formData.name.split(' ')[0] || '',
            LastName: formData.name?.split(' ')[1] || ' ',
            ZipCode: zipCode.trim(),
            PostalCode: zipCode.trim(),
            Email: formData.email,
            State: state,
            City: city,
            Phone: formData.phone,
            Address: finalAddress,
            Address2: formData?.address2 ?? '',
            Comments: '',
            SignUpForUpdates: '',
            ConceptId: Number(conceptId),
            CurrentPage: url,
            LeadSource: brandData?.refer_a_friend_lead_source || "WEB",
            LeadOrigin: brandData?.refer_a_friend_lead_origin || "WEB",
            PhoneType: '',
            IsLeadOnly: true,
            CampaignName: campaignName || 'N/A',
            CallType: "WEB",
            ConceptCalledId: Number(conceptId),
            EmailOptOut: emailOptOut,
            VendorId: vendorId,
            IsTest: isTestEnvironment(),
            ConceptCode: conceptCode,
            VendorName: vendorName,
            CustomerType: 'Residential',
            LeadSourceID: defaultLeadSourceId,
            Note: noteComment
        };

        if (franchiseWebLocationId) {
            requestBody.FranchiseWebLocationId = franchiseWebLocationId;
            requestBody.WebLocationId = franchiseWebLocationId;
        }
        if (franchiseId) {
            requestBody.FranchiseId = franchiseId;
        }

        function formatField(label: string, value: string | null | undefined): string {
            return value ? `${label}: ${value}, ` : '';
        }
        
        const getReferralFormData = this.getReferralFormData();
        let mergeAllFields = brandData?.send_lead_referee_info
        ? `<br>${formatField('First Name', getReferralFormData?.referFirstName)}${formatField('Last Name', getReferralFormData?.referLastName)}${formatField('Email', getReferralFormData?.referEmail)}${formatField('Phone', getReferralFormData?.referPhone)} ${formatField('Zip Code', getReferralFormData?.referZipCode)}`
        : `<br>${formatField('Referral First Name', getReferralFormData?.referralFirstName)}${formatField('Referral Last Name', getReferralFormData?.referralLastName)}${formatField('Referral Email', getReferralFormData?.referralEmail)}${formatField('Referral Phone', getReferralFormData?.referralPhone)}${formatField('Referral Address', getReferralFormData?.referralAddress)}${formatField('Referral Address 2', getReferralFormData?.referralAddress2)}${formatField('Referral Zip Code', getReferralFormData?.referralZipCode)}`;
    
        // Remove the trailing comma and space
        mergeAllFields = mergeAllFields.slice(0, -2);
        requestBody.Note += mergeAllFields;

        if (brandData.send_lead_referrer_info) {
            requestBody.referralFirstName = this.referralFirstName.value;
            requestBody.referralLastName = this.referralLastName.value;
            requestBody.referralZipCode = this.referralZipCode.value;
            requestBody.referralPhone = this.referralPhone.value;
            requestBody.referralEmail = this.referralEmail.value;
            requestBody.referralReason = '';
        }

        if (this.sentCommentAndNote) {
            requestBody.Comments = noteComment;
        }

        return requestBody;
    }

    async contactUsApiCall() {
        startLoader();
        const conceptCode = document.getElementById('conceptCode') as HTMLInputElement;
        const brandData = await getBrandDetailsFromBrandJson(conceptCode?.value);
        const formData = this.getReferralFormData();

        // Determine which form data to use based on brandData.send_lead_referee_info and brandData.send_lead_referrer_info
        let selectedFormData;
        if (brandData?.send_lead_referee_info) {
            selectedFormData = {
                firstName: formData.referralFirstName,
                lastName: formData.referralLastName,
                zipCode: formData.referralZipCode,
                phone: formData.referralPhone,
                email: formData.referralEmail,
                address: formData.referralAddress,
                address2: formData.referralAddress2
            };
        } else if (brandData?.send_lead_referrer_info) {
            const resultAdd = await discoveryAPI(formData.referZipCode);
            const outputData = resultAdd?.items[0];
            selectedFormData = {
                firstName: formData.referFirstName,
                lastName: formData.referLastName,
                zipCode: formData.referZipCode,
                phone: formData.referPhone,
                email: formData.referEmail,
                address: (document.getElementById('default_address_refer_a_friend') as HTMLInputElement)?.value ?? "No Address collected",
                address2: '',
                city: '',
                state: '',
                country: ''
            };
            if (outputData?.address?.city) {
                selectedFormData.city = outputData?.address?.city;
            }
            if (outputData?.address?.stateCode) {
                selectedFormData.state = outputData?.address?.stateCode;
            }

            if (outputData?.address?.countryCode) {
                selectedFormData.country = outputData?.address?.countryCode;
            }

        }

       
        const conceptId = document.getElementById('conceptId') as HTMLInputElement;
        const zipCodeText = (document.querySelector('label[for="my-refer-zip-code"]') as HTMLElement)?.innerText.trim();

        let vendorId;
        let vendorName;

        try {
            if (brandData) {
                vendorId = brandData.vendorId;
                vendorName = brandData.vendorName;
            }

            const requestBody: any = this.contactCreateRequestBody(selectedFormData, conceptId, vendorId, vendorName, brandData);
            if(brandData?.refer_note_text_contactus){
                requestBody.Note = brandData?.refer_note_text_contactus ?? '';
            }
            if(brandData?.refer_comment_text_contactus && this.sentCommentAndNote){
                requestBody.Comments = brandData?.refer_note_text_contactus ?? '';
            }
            await this.contactCallContactUsEndpoint(requestBody);
            stopLoader();
        } catch (error) {
            console.error(`Error in Referral>index.ts file in the contactUsApiCall function while fetching the Brand JSON. Error message: ${error}`);
            stopLoader();
        }
    }

    private contactCreateRequestBody(formData: any, conceptId: any, vendorId: any, vendorName: any, brandData: any) {

        let city = sessionStorage.getItem('manualCity');
        let state = sessionStorage.getItem('manualState');
        let country = sessionStorage.getItem('countryName');

        let getsuggestionFlag = sessionStorage.getItem("suggestion");
        const suggestAddr = sessionStorage.getItem('suggestedAddress') as string;

        if (getsuggestionFlag === "true" && suggestAddr && brandData?.send_lead_referee_info) {
              city = JSON.parse(suggestAddr).city;
              state = JSON.parse(suggestAddr).stateCode;
              country = JSON.parse(suggestAddr).country;
        }else {
                city = "";
                state = "";
                country = "";
            if(brandData?.send_lead_referrer_info){
                city = formData?.city;
                state = formData?.state;
                country = formData?.country;
            }
        }
        

        const getDefault = (value: any, defaultValue: any = '') => value ?? defaultValue;
    
        return {
            FirstName: getDefault(formData.firstName),
            LastName: getDefault(formData.lastName),
            ZipCode: getDefault(formData.zipCode).trim(),
            Phone: getDefault(formData.phone),
            Email: getDefault(formData.email),
            city: getDefault(city),
            state: getDefault(state),
            country: getDefault(country),
            address: getDefault(formData.address),
            address2: getDefault(formData.address2),
            SignUpForUpdates: false,
            IsLocalized: false,
            IsTest: isTestEnvironment(),
            ConceptId: getDefault(conceptId?.value),
            VendorId: getDefault(vendorId),
            VendorName: getDefault(vendorName)
        };
    }

    private async contactCallContactUsEndpoint(requestBody: any) {
        try {
            const request = {
                method: 'POST',
                url: apiConfig.CONTACT_US_SEND,
                data: requestBody
            };

            await apiRequest(request);
            const submitObj = {
                event: 'opus_refferal_form_submit_ev',
                status: 'success'
            }
            gtmCustomEventLeadFlow(submitObj);
            this.redirectConfirmationPage();
        } catch (error) {
            const submitObj = {
                event: 'opus_refferal_form_submit_ev',
                status: 'failure'
            }
            gtmCustomEventLeadFlow(submitObj);
            console.error(`Error in Referral >  file in the contactCallContactUsEndpoint function while calling the contactus endpoint: ${error}`);
        }
    }

    public readFormData() {
        this.bookingPayload.FirstName = this.referFirstName ? this.referFirstName.value : '';
        this.bookingPayload.LastName = this.referLasttName ? this.referLasttName.value : '';
        this.bookingPayload.Phone = this.referPhone ? this.referPhone.value : '';
        this.bookingPayload.ZipCode = this.referralZipCode ? this.referralZipCode.value : '';
        this.bookingPayload.PostalCode = this.referralZipCode ? this.referralZipCode.value : '';
        this.bookingPayload.Email = this.referEmail ? this.referEmail.value : '';
        this.bookingPayload.Address = this.referralAddress ? this.referralAddress.value : '';
        this.bookingPayload.Address2 = this.referralAddress2 ? this.referralAddress2.value : '';
        this.bookingPayload.LeadSource = "Referral";
        this.bookingPayload.LeadOrigin = "Referral";
        this.bookingPayload.CampaignName = "Referral";
        this.bookingPayload.TagName = "Referral";
        this.bookingPayload.ServiceText = "Lead Only";
        this.bookingPayload.ServiceID = 0;
        this.bookingPayload.Warranty = false;
        this.bookingPayload.TimeSlotTypeId = 0;
        this.bookingPayload.MakeId = 0;
        this.bookingPayload.LeadSourceId = 0;
        this.bookingPayload.VendorId = 0;
        this.bookingPayload.ConceptId = 0;

    }

    openPropertyValidationModal(addressParam: string, suggestStr: any) {
        stopLoader();
        const callPopupModal = document.getElementById('address-modal')
        callPopupModal?.click()
        const enteredAddr = document.querySelector('label[for="entered-address-radio"]');
        const suggestAddr = document.querySelector('label[for="suggested-address-radio"]');
        const suggestJSON = suggestStr !=='undefined' && JSON.parse(suggestStr);
        if (enteredAddr && suggestAddr) {
            enteredAddr.textContent = addressParam;
            suggestAddr.textContent = suggestJSON.address.label;
        }
       

    }

    public getReferralFormData() {
        return {
            referFirstName: this.referFirstName ? this.referFirstName.value : '',
            referLastName: this.referLasttName ? this.referLasttName.value : '',
            referPhone: this.referPhone ? this.referPhone.value : '',
            referZipCode: this.referZipCode ? this.referZipCode.value : '',
            referEmail: this.referEmail ? this.referEmail.value : '',
            referralFirstName: this.referralFirstName ? this.referralFirstName.value : '',
            referralLastName: this.referralLastName ? this.referralLastName.value : '',
            referralPhone: this.referralPhone ? this.referralPhone.value : '',
            referralZipCode: this.referralZipCode ? this.referralZipCode.value : '',
            referralEmail: this.referralEmail ? this.referralEmail.value : '',
            referralAddress: this.referralAddress ? this.referralAddress.value : '',
            referralAddress2: this.referralAddress2 ? this.referralAddress2.value : ''
        };
    }

    public validateFields(): boolean {
        try {
            document.querySelectorAll('.invalid-field').forEach((e: any) => e.classList.remove('invalid-field'));
            const errors: any = {};

            const validateField = (field: HTMLInputElement, regex: RegExp, errorMessage: string) => {
                if (field?.value === '') {
                    errors[field?.id] = '';
                } else if (field?.value !== '' && !regex.test(field?.value)) {
                    errors[field?.id] = errorMessage;
                } else if (field?.value && (field?.id === 'frnd-refer-phone' || field?.id === 'my-refer-phone') && field?.value.length < 14) {
                    errors[field?.id] = errorMessage;
                }
            };

            if (this.referFirstName) {
                validateField(this.referFirstName, REGEX.sendName, errorMessage.fname);
            }
            if (this.referLasttName) {
                validateField(this.referLasttName, REGEX.sendName, errorMessage.lname);
            }
            if (this.referPhone) {
                validateField(this.referPhone, REGEX.mobileNumberRegex, errorMessage.phone);
            }
            if (this.referZipCode) {
                validateField(this.referZipCode, REGEX.sendZip, errorMessage.zip);
            }
            if (this.referEmail) {
                validateField(this.referEmail, REGEX.sendEmail, errorMessage.email);
            }
            if (this.referralFirstName) {
                validateField(this.referralFirstName, REGEX.sendName, errorMessage.fname);
            }
            if (this.referralLastName) {
                validateField(this.referralLastName, REGEX.sendName, errorMessage.lname);
            }
            if (this.referralPhone) {
                validateField(this.referralPhone, REGEX.mobileNumberRegex, errorMessage.phone);
            }
            if (this.referralZipCode) {
                validateField(this.referralZipCode, REGEX.sendZip, errorMessage.zip);
            }
            if (this.referralEmail) {
                validateField(this.referralEmail, REGEX.sendEmail, errorMessage.email);
            }
            if (this.referralAddress) {
                validateField(this.referralAddress, REGEX.sendAddress1, errorMessage.address);
            }

            Object.keys(errors).forEach((fieldId, index) => {
                const field: any = document.getElementById(`${fieldId}`);
                const fieldError: any = document.getElementById(`${fieldId}-error-msg`);
                if (field?.parentNode) {
                    const errorMessage = errors[fieldId];
                    const errorElement = document.createElement('span');
                    errorElement.className = 'error-msg';
                    errorElement.id = `${fieldId}-error-msg`;
                    errorElement.textContent = errorMessage;
                    field.classList.add("invalid-field");
                    if (fieldError?.classList.contains('hidden')) {
                        fieldError.classList.remove('hidden');
                    }
                }
                if (index === 0 && field) {
                    field.focus();
                }
            });

            return Object.keys(errors).length === 0;
        } catch (error) {
            console.error('Error validating fields:', error);
            return false;
        }
    }

    public handleCommonInputError(inputElement: any, errorId: any, validationRegex: any, errorMessage: any) {
        const getErrMsg = (document.getElementById(errorId) as HTMLElement)?.getAttribute('aria-describedby');
        const fieldError = document.getElementById(errorId) as HTMLElement;

        if (inputElement.value !== '' && !validationRegex.test(inputElement?.value?.trim())) {
            if (fieldError) {
                fieldError.classList.remove('hidden');
                fieldError.innerHTML = getErrMsg ?? errorMessage;
            }
        } else {
            inputElement.classList.remove("invalid-field");
            if (fieldError) {
                fieldError.classList.add('hidden');
            }
        }
    }

    public redirectConfirmationPage() {
        const referralRedirectionUrl = (document.getElementById('referral-redirect') as HTMLInputElement)?.value;
        if (referralRedirectionUrl) {
            window.location.href = referralRedirectionUrl;
        }
    }

    public closeAddressBumperForm(){
            // const findLocalBtn = document.querySelector("#glb-find-local") as HTMLElement;
            // if (findLocalBtn) {
            //     findLocalBtn.classList.add("hidden");
            //     document.querySelectorAll("body > div[modal-backdrop]")?.forEach((e)=>e?.remove())
            // }
            const findLocalBtn = document.querySelector(".find-local") as HTMLElement;
            findLocalBtn?.click();
    }

    private setupInlineValidation(): void {
        const fields = {
          address1: document.getElementById('modal-address-1') as HTMLInputElement,
          address2: document.getElementById('modal-address-2') as HTMLInputElement,
          city: document.getElementById('modal-city') as HTMLInputElement,
          state: document.getElementById('modal-states') as HTMLSelectElement,
          zipCode: document.getElementById('modal-zipcode') as HTMLInputElement
        };
    
        fields.address1?.addEventListener('input', (e) => this.validateField(e.target as HTMLInputElement, () => this.addressValidation(fields.address1.value, true)));
        fields.address2?.addEventListener('input', (e) => this.validateField(e.target as HTMLInputElement, () => this.addressValidation(fields.address2.value, false)));
        fields.city?.addEventListener('input', (e) => this.validateField(e.target as HTMLInputElement, () => this.cityValidation(fields.city.value)));
        fields.zipCode?.addEventListener('input', (e) => this.validateField(e.target as HTMLInputElement, () => this.zipValidation(fields.zipCode.value)));
        fields.state?.addEventListener('change', (e) => this.validateField(e.target as HTMLSelectElement, () => this.stateValidation(fields.state.value)));
      }

      private validateField(element: HTMLInputElement | HTMLSelectElement, validationFunction: () => string): void {
        const error = validationFunction();
        const errorElement = element.nextElementSibling as HTMLSpanElement;
        if (error) {
          element.classList.add('invalid-field');
          if (errorElement?.classList.contains('error-msg')) {
            errorElement.textContent = error;
          } else {
            const newErrorElement = document.createElement('span');
            newErrorElement.className = 'error-msg';
            newErrorElement.textContent = error;
            element.parentNode?.insertBefore(newErrorElement, element.nextSibling);
          }
        } else {
          element.classList.remove('invalid-field');
          if (errorElement?.classList.contains('error-msg')) {
            errorElement.remove();
          }
        }
      }

      private addressValidation(inputVal: string, isRequired: boolean): string {
        const addrRegex = REGEX.noBracketAddress;
        if (isRequired) {
          if (!inputVal.trim()) {
            return 'Address Line 1 is required.';
          }
          if (!addrRegex.test(inputVal)) {
            return 'Enter valid address.';
          }
        } else if (inputVal.trim() && !addrRegex.test(inputVal)) {
            return 'Enter valid address.';
        }
        return '';
      }
    
      private zipValidation(inputVal: string): string {
        const zipRegex = REGEX.sendZip;
        if (!inputVal.trim()) {
          return 'Zip code is required.';
        }
        if (!zipRegex.test(inputVal.trim()) || inputVal.trim() === '00000') {
          return 'Invalid zip code format.';
        }
        return '';
      }
    
      private cityValidation(inputVal: string): string {
        const cityRegex = REGEX.sendName;
        if (!inputVal.trim()) {
          return 'City is required.';
        }
        if (!cityRegex.test(inputVal)) {
          return 'Enter valid city.';
        }
        return '';
      }
    
      private stateValidation(inputVal: string): string {
        if (!inputVal || inputVal === '') {
          return 'State is required.';
        }
        return '';
      }
      private getFormFields(): any {
        const fields = {
    
          zipCode: (document.getElementById('modal-zipcode') as HTMLInputElement).value,
          address1: (document.getElementById('modal-address-1') as HTMLInputElement).value,
          address2: (document.getElementById('modal-address-2') as HTMLInputElement).value,
          city: (document.getElementById('modal-city') as HTMLInputElement).value,
          state: (document.getElementById('modal-states') as HTMLSelectElement).value,
        };
        return fields;
      }
      
      public validateForm(): boolean {
        const formFields = this.getFormFields();
        document.querySelectorAll('.step-2 .error-msg').forEach(e => e.remove());
        // document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));
        document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));
    
        let errors: { [key: string]: string } = {};
        errors['modal-address-1'] = this.addressValidation(formFields.address1, true);
        errors['modal-address-2'] = this.addressValidation(formFields.address2, false);
        errors['modal-zipcode'] = this.zipValidation(formFields.zipCode);
        errors['modal-city'] = this.cityValidation(formFields.city);
        errors['modal-states'] = this.stateValidation(formFields.state);
    
        Object.keys(errors).forEach((fieldId) => {
          const field: any = document.getElementById(fieldId);
          if (field && field.parentNode) {
            const errorMessage = errors[fieldId];
            if (errorMessage) {
              const errorElement = document.createElement('span');
              errorElement.className = 'error-msg';
              errorElement.textContent = errorMessage;
              field.classList.add("invalid-field");
              field.parentNode.appendChild(errorElement);
            }
          }
        });
    
        return Object.values(errors).every(error => !error);
      }

      public handleButtonClickAndError(buttonId: string, errorMessageId: string) {
        const btnClick = document.getElementById(buttonId);
        const errorMessage = document.getElementById(errorMessageId);
    
        if (btnClick) {
            btnClick.click();
        }
    
        if (errorMessage) {
            errorMessage.classList.remove('hidden');
            const referNoServiceError = document.getElementById('refer-form-no-service');
            if(referNoServiceError){
                referNoServiceError.classList.add('hidden');
            }
        }
    }
}

new ReferralService();