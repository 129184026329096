import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { OnLoaderServices } from '../services';
import { apiConfig } from '../config/apiConfig';
import { isTouchDevice } from '../util/device-detection';
import { isTestEnvironment } from '../apiCaching/POST-Requests/helperModules/helperFunctions';

// Create a custom Axios instance with your desired configuration
const instance = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',

}
});


const instance2 = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'apikey': process.env.JS_API_KEY,

}
});
const errorLoggerURLTransformer = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'apikey': process.env.URL_TRANSFORMER_ERROR_LOG_KEY,

  }
});

 

// Define a type for your API response data 
interface ApiResponse<T> {
  success: boolean;
  data: T;
  message: string;
}

 

// Define a type for your API error response data 
interface ApiError {
  error: string;
  code: number;
  Message?: number;
}


async function apiRequest<T>(config: AxiosRequestConfig): Promise<T> {
  try {
    const response: AxiosResponse<T> = await instance.request(config);
    return response.data; // Extract the actual data from the response
  } catch (error: any) {
    throw handleApiError(error, config.url); // Handle and rethrow the error
  }
}


export async function apiRequest2<T>(config: AxiosRequestConfig): Promise<T> {
  try {
    const response: AxiosResponse<T> = await instance2.request(config);
    return response.data; // Extract the actual data from the response
  } catch (error: any) {
    throw handleApiError(error, config.url); // Handle and rethrow the error
  }
}
export async function errorLoggerRequest<T>(config: AxiosRequestConfig): Promise<T> {
  try {
    const response: AxiosResponse<T> = await errorLoggerURLTransformer.request(config);
    return response.data; // Extract the actual data from the response
  } catch (error: any) {
    throw handleApiError(error, config.url); // Handle and rethrow the error
  }
}

export async function apiRequestForWrapper<T>(config: AxiosRequestConfig): Promise<T> {
  try {
    const response: AxiosResponse<T> = await instance.request(config);
    return response.data; // Extract the actual data from the response
  } catch (error: any) {
    throw handleWrapperApiError(error); // Handle and rethrow the error
  }
}
 
 
 

// Handle API errors and extract meaningful information
function handleApiError(error: AxiosError<ApiError>, callingURL? :any): Error {
  if (error.response) {
    // The request was made and the server responded with a non-2xx status code
    const errorMessage = error.response.data.Message ?? 'API Error';
    const statusCode = error.response.status;
    const obj = {
      httpStatusCode: `${error.response.status}`,
      message: `${error.response.data.Message ?? "No message recieved from API response"} - Source URL - ${window.location.href}`,
      timeStamp: new Date(Date.now()),
      apiEndpoint: removeApiKey(callingURL),
      deviceType: isTouchDevice() ? `Mobile Device - UserAgent: ${navigator.userAgent}` : `Desktop Device - UserAgent: ${navigator.userAgent}`
    }
    callErrorLogger(obj);
    return new Error(`${errorMessage} (Status: ${statusCode})`);
  } else if (error.request) {
    // The request was made, but no response was received
    return new Error('No response received from the server');
  } else {
    // Something happened in setting up the request that triggered an error
    return new Error('Error occurred while setting up the request');
  }
}

//function to strip API Key
function removeApiKey(url:any) {
  const urlObj = new URL(url);
  // Check if 'apikey' parameter exists
  if (urlObj.searchParams.has('apikey')) {
      urlObj.searchParams.delete('apikey');
  }
  return urlObj.toString();
}

function handleWrapperApiError(error: AxiosError<ApiError>): Error {
  if (error.response) {
    // The request was made and the server responded with a non-2xx status code
    const errorMessage = error.response.data.Message ?? 'API Error';
    const statusCode = error.response.status;
    const data = {
      message: errorMessage,
      status: statusCode
    }
    return new Error(JSON.stringify(data));
  } else if (error.request) {
    // The request was made, but no response was received
    return new Error('No response received from the server');
  } else {
    // Something happened in setting up the request that triggered an error
    return new Error('Error occurred while setting up the request');
  }
}

//call error logger
async function callErrorLogger(err:any) {
  const testEnvironment:boolean = isTestEnvironment();
  const errorService = OnLoaderServices.getInstance();
  const errors = errorService.getLoggedErrors();
  errors.push(err);
  errors.forEach((e:any) => {
    e.message = `${e.message} - code:${Date.now()}`;
  });
  const url = `${window.location.origin}/${apiConfig.ApiLogerrorEndpoint}`;
  if(!err.apiEndpoint){
    if(testEnvironment)
      console.log("Error in determining the API endpoint of the failed call");
    return
  }
  if(err.apiEndpoint!== url){
    callErrorLoggerEndpoint(url, errors, testEnvironment);
  }
  else if(testEnvironment){
      console.error("Error Logger API failed..")
  }
}

async function callErrorLoggerEndpoint(url:string, errors:any, testEnvironment:boolean){
  const request = {
    method: 'POST',
    url,
    data: {
      "source": "js-code",
      "errorMessages": errors
    },
  };
  try{
    if(testEnvironment)
      console.log(request)
    const response = await errorLoggerRequest(request);
    if(testEnvironment)
      console.log('Error log sent to URL Transformer:', response);
  }
  catch(err){
    if(testEnvironment)
      console.error('Failed to send the error log to URL Transformer', err);
  }
  
}
 

export default apiRequest;